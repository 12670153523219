<template>
    <div>
        <Loading v-if="!requested" />
        <div class="account" v-if="requested">
            <div class="main">
                <navbar />
                <v-overlay z-index="4" :value="overlay" class="overlay">
                    <div class="modal">
                        <div class="modal-header">
                            <div class="modal-title">Edit Profile</div>
                            <button @click="overlay=false">
                                <v-icon color="#777">
                                    {{icons.mdiClose}}
                                </v-icon></button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="form-row">
                                    <div class="form-group">
                                        <label>Phone Number</label>
                                        <input class="form-control" type="text" value="+06 11 1111 111">
                                    </div>
                                    <div class="form-group">
                                        <label>Email id</label>
                                        <input class="form-control" type="email" value="johndoe@gmail.com">
                                    </div>
                                    <div class="form-group">
                                        <label>Phone Number</label>
                                        <input class="form-control" type="password" value="0123456789">
                                    </div>
                                </div>  
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn mr-2 btn-outline" @click="overlay=false">CANCEL</button>
                            <button type="button" class="btn ml-2 btn-primary">UPDATE</button>
                        </div>
                    </div>
                </v-overlay>

                <div class="wrapper">   
                    <div class="col header">
                        <div class="row user">
                            <div class="head-text">
                                Account
                                <div class="user-edit-top" @click="overlay=true">
                                    <v-icon class="user-icon">
                                        {{icons.mdiWrench}}
                                    </v-icon>
                                </div>
                            </div>
                            <div class="profile">
                                    <v-card width="60" rounded="circle" >
                                        <v-img width="60" :src="userIcon"></v-img>
                                    </v-card>
                                <div style="display:flex;flex-direction:column;align-items:center">
                                    <div class="user-info">
                                        <div class="username">John Doe</div>
                                        <div class="phone">+011 1242-0395</div>
                                        <div class="email">johndoe@yahoo.com</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row nav">
                            <div class="nav-button" :class="{'nav-button_active': tab== 1}" @click="tab=1">
                                <v-btn class="mr-1" fab dark x-small color="darkgrey">
                                    <v-icon dark size="20"> 
                                        {{icons.mdiClipboardTextMultiple}}
                                    </v-icon>
                                </v-btn>
                                Order History
                            </div>
                            <div class="nav-button" :class="{'nav-button_active': tab== 2}" @click="tab=2">
                                <v-btn class="mr-1" fab dark x-small color="darkgrey">
                                    <v-icon dark size="20"> 
                                        {{icons.mdiTicketPercent }}
                                    </v-icon>
                                </v-btn>
                                Coupon
                            </div>
                            <div class="nav-button" :class="{'nav-button_active': tab== 3}" @click="tab=3">
                                <v-btn class="mr-1" fab dark x-small color="darkgrey">
                                    <v-icon dark size="20"> 
                                        {{icons.mdiCreditCard}}
                                    </v-icon>
                                </v-btn>
                                Payment Option
                            </div>
                            <div class="nav-button" :class="{'nav-button_active': tab== 4}" @click="tab=4">
                                <v-btn class="mr-1" fab dark x-small color="darkgrey">
                                    <v-icon dark size="20"> 
                                    {{icons.mdiCardAccountMail}}
                                    </v-icon>
                                </v-btn>
                                Membership
                            </div>
                            <div class="nav-button" :class="{'nav-button_active': tab== 5}" @click="tab=5">
                                <v-btn class="mr-1" fab dark x-small color="darkgrey">
                                    <v-icon dark size="20"> 
                                        {{icons.mdiSafe}}
                                    </v-icon>
                                </v-btn>
                                Drinks Storage
                            </div>
                            <div class="nav-button" :class="{'nav-button_active': tab== 6}" @click="tab=6">
                                <v-btn class="mr-1" fab dark x-small color="darkgrey">
                                    <v-icon dark size="20"> 
                                        {{icons.mdiMapMarker}}
                                    </v-icon>
                                </v-btn>
                                Addresses
                            </div>
                        </div>

                    </div>

                    <div class="col body">
                        <div class="wallet">
                            <div class="credit">
                                <div class="credit-pre">
                                    <div class="mobile">A.</div> <div class="desktop">Account</div> Credits
                                </div>
                                <div class="credit-price">
                                    $0.00
                                </div>
                            </div>
                            <div class="credit">
                                <div class="credit-pre">
                                    Balance
                                </div>
                                <div class="credit-price">
                                    $0.00
                                </div>
                            </div>
                            <div class="credit">
                                <div class="credit-pre">
                                    Points
                                </div>
                                <div class="credit-price">
                                    $0.00
                                </div>
                            </div>
                        </div>

                        <div class="content">
                            <div class="history" v-if="tab==1">
                                <h2>Past Orders</h2>
                            </div>
                            <div class="coupon" v-if="tab==2">
                                <h2>Coupon</h2>
                            </div>

                            <div class="payment-card" v-if="tab==3">
                                <h2 >
                                    Payment Option
                                </h2>
                                <div class="card">
                                    <div class="card-info">
                                        <div class="card-img">
                                            <v-card width="50">
                                                <v-img height="50" src="https://fomy-assets-sandbox.oss-ap-southeast-3.aliyuncs.com/images/2/2021/05/ahrqoFr0ltL0FFfekphfheL8gxir8h.jpg"></v-img>
                                            </v-card>
                                        </div>
                                        <div class="card-detail">
                                            <div class="card-name">Main card</div>
                                            <div class="card-no"> **** 9432</div>
                                        </div>
                                    </div>
                                    <div>
                                        <v-btn icon small>
                                            <v-icon>
                                                {{icons.mdiChevronRight}}
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                                <div class="add-payment-card">
                                    <div>
                                        Add new card
                                    </div>
                                    <div>
                                        <v-btn icon small>
                                            <v-icon>
                                                {{icons.mdiPlus}}
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>

                            <div class="membership" v-if="tab==4">
                                <h2>Membership</h2>
                            </div>

                            <div class="storage" v-if="tab==5">
                                <h2>Drinks storage</h2>
                            </div>

                            <div class="address" v-if="tab==6">
                                <h2 class="addr-text">
                                    Manage Addresses
                                </h2>

                                <div class="addr-cont">
                                    <div >  

                                    </div>
                                </div>

                                <div class="addr-tab">
                                    <div class="addr-title">My Home</div>
                                    <div class="addr-body">
                                        <div class="addr-desc">
                                            Lorem ipsum dolor 
                                        </div>
                                        <v-btn icon small>
                                            <v-icon>
                                                {{icons.mdiChevronRight}}
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </div>

                                <div class="addr-tab">
                                    <div class="addr-title">My Office</div>
                                    <div class="addr-body">
                                        <div class="addr-desc">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                        </div>
                                        <v-btn icon small>
                                            <v-icon>
                                                {{icons.mdiChevronRight}}
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </div>

                                <div class="add-address">
                                    <div>
                                        Add new address
                                    </div>
                                    <div>
                                        <v-btn icon small>
                                            <v-icon>
                                                {{icons.mdiPlus}}
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mobile collapsible mr-0">
                            <v-expansion-panels focusable>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-btn class="mr-1" fab dark x-small color="darkgrey">
                                                <v-icon dark size="20"> 
                                                    {{icons.mdiClipboardTextMultiple}}
                                                </v-icon>
                                            </v-btn>
                                        Order History
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div class="history">
                                            <h2>Past Orders</h2>
                                            <div class="history-cont">
                                                <div>No Order History</div>
                                                <div class="order-card">

                                                </div>
                                            </div>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-btn class="mr-1" fab dark x-small color="darkgrey">
                                                <v-icon dark size="20"> 
                                                    {{icons.mdiTicketPercent }}
                                                </v-icon>
                                            </v-btn>
                                            Coupon
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div class="coupon">
                                            <h2>Coupon</h2>
                                            <div class="coupon-cont">
                                                <div>No Coupon</div>
                                                <div class="coupon-card">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-btn class="mr-1" fab dark x-small color="darkgrey">
                                                <v-icon dark size="20"> 
                                                    {{icons.mdiCreditCard}}
                                                </v-icon>
                                            </v-btn>
                                            Payment Option
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>

                                        <div class="payment-card">
                                            <h2 >
                                                Payment Option
                                            </h2>
                                            <div class="card">
                                                <div class="card-info">
                                                    <div class="card-img">
                                                        <v-card width="50">
                                                            <v-img height="50" src="https://fomy-assets-sandbox.oss-ap-southeast-3.aliyuncs.com/images/2/2021/05/ahrqoFr0ltL0FFfekphfheL8gxir8h.jpg"></v-img>
                                                        </v-card>
                                                    </div>
                                                    <div class="card-detail">
                                                        <div class="card-name">Main card</div>
                                                        <div class="card-no"> **** 9432</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <v-btn icon small>
                                                        <v-icon>
                                                            {{icons.mdiChevronRight}}
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                            <div class="add-payment-card">
                                                <div>
                                                    Add new card
                                                </div>
                                                <div>
                                                    <v-btn icon small>
                                                        <v-icon>
                                                            {{icons.mdiPlus}}
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>

                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-btn class="mr-1" fab dark x-small color="darkgrey">
                                                <v-icon dark size="20"> 
                                                {{icons.mdiCardAccountMail}}
                                                </v-icon>
                                            </v-btn>
                                            Membership
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div class="membership">
                                            <h2>Membership</h2>

                                            <div class="membership-cont">
                                                <div>No Membership</div>
                                                <div class="membership-card">
                                                </div>
                                            </div>
                                        </div>
                                    </v-expansion-panel-content>

                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-btn class="mr-1" fab dark x-small color="darkgrey">
                                                <v-icon dark size="20"> 
                                                    {{icons.mdiSafe}}
                                                </v-icon>
                                            </v-btn>
                                            Drinks Storage
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div class="storage">
                                            <h2>Drinks Storage</h2>

                                            <div class="storage-cont">
                                                <div>Empty</div>
                                                <div class="storage-card">
                                                </div>
                                            </div>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-btn class="mr-1" fab dark x-small color="darkgrey">
                                                <v-icon dark size="20"> 
                                                    {{icons.mdiMapMarker}}
                                                </v-icon>
                                            </v-btn>
                                            Addresses
                                        </div>
                                    </v-expansion-panel-header>

                                    <v-expansion-panel-content>
                                        <div class="address">
                                            <h2 class="addr-text">
                                                Manage Addresses
                                            </h2>
                                            <div class="addr-cont">
                                                <div >  
                                                </div>
                                            </div>
                                            <div class="addr-tab">
                                                <div class="addr-title">My Home</div>
                                                <div class="addr-body">
                                                    <div class="addr-desc">
                                                        Lorem ipsum dolor 
                                                    </div>
                                                    <v-btn icon small>
                                                        <v-icon>
                                                            {{icons.mdiChevronRight}}
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                            <div class="addr-tab">
                                                <div class="addr-title">My Office</div>
                                                <div class="addr-body">
                                                    <div class="addr-desc">
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                                    </div>
                                                    <v-btn icon small>
                                                        <v-icon>
                                                            {{icons.mdiChevronRight}}
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                            <div class="add-address">
                                                <div>
                                                    Add new address
                                                </div>
                                                <div>
                                                    <v-btn icon small>
                                                        <v-icon>
                                                            {{icons.mdiPlus}}
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                        </div>

                    </div>

                </div>
            </div>
        </div>
        <NavFooter v-if="requested"/>
    </div>
</template>
<script>
import NavFooter from '@/components/common/NavFooter.vue'
import Loading from "@/view/Loading.vue";
import Navbar from "./Navbar.vue"
import userIcon from "@/assets/user.png"
import { 
    mdiPlus,
    mdiChevronRight,
    mdiLeadPencil,
    mdiClipboardTextMultiple ,
    mdiTicketPercent,
    mdiCreditCard,
    mdiSafe,
    mdiMapMarker,
    mdiWrench,
    mdiCardAccountMail,
    mdiClose
} from '@mdi/js';

export default {
    name: "Account",
    components:{
        NavFooter,
        Loading,
        Navbar
    },
    data(){
        return{
            icons:{
                mdiPlus,
                mdiChevronRight,
                mdiLeadPencil,
                mdiClipboardTextMultiple,
                mdiTicketPercent,
                mdiCreditCard,
                mdiSafe,
                mdiMapMarker,
                mdiWrench,
                mdiCardAccountMail,
                mdiClose
            },
            overlay:false,
            tab:1,
            requested: false,
            userIcon:userIcon
        }
    },
    created(){
        setTimeout(()=>{
            this.requested=true;
        },300)
    },
    methods:{

    }
}

</script>

<style lang="scss" scoped>
@import "@/components/common/css/global.scss";
@import "@/components/common/css/common.scss";

.account{
    background: #f3f7f8;
    min-height: 100vh;
    @media screen and (max-width: $bp-mobile){
        padding-bottom: 80px;
    }
}

.modal{
    background: white;
    width: 300px;
    color: black;
    margin: 0 auto;
    @media screen and (max-width: $bp-mobile){
        max-width: 100%;
    }
}
.modal-header{
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
}
.modal-title{
    font-size: 1.2rem;
    font-weight: 500;
}
.modal-body{
    padding: 1rem;
    border-bottom: 1px solid #e5e5e5;

    .form-row{  
        .form-group{
            margin-bottom: 1rem;

            label{
                display: inline-block;
                margin-bottom: .5rem;
                font-weight: 500;
                font-size: 13px;
            }
            .form-control{
                display: block;
                width: 100%;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                font-size: 13px;
                border-radius: 2px;
                min-height: 38px;
                border: 1px solid #ced4da;
                background: #fff;
                padding: 6px 12px;
            }
        }
    }
}
.modal-footer{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    .btn{
        text-align: center;
        padding: 12px 16px;
        width: 100%;
        display: block;
        font-size: 14px;
        border: 1px solid;
        border-radius: 2px;
    }
    .btn-outline{
        border-color: $red1 !important;
        color: $red1;
    }
    .btn-primary{
        background: $red1 !important;
        border-color: $red1 !important;
        color: white;
        font-weight: 600;
        
    }
}

.wrapper{
    display: flex;
    max-width: 1600px;
    margin: 0 auto;
    padding: 10px 40px 60px 40px;
    @media screen and (max-width: 860px){
        padding: 1rem 0px;
    }
    @media screen and (max-width: $bp-mobile){
        flex-direction: column;
        align-items: center;
        padding: 0 1rem;
    }
}
.col{
    margin: 1rem 1rem 0;
    width: 100%;
    @media screen and (max-width: $bp-mobile){
        margin: 0;
    }
}
.header{
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0.2;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: 0 0 2px 0 #cccccc;
    border-radius: 4px;
    @media screen and (max-width: $bp-mobile){
        width: 100%;
        margin-top: 1rem;
    }
}
.row{
    width: 100%;
}
.user{
    padding: 16px;
    border-bottom:1px solid #eeeeee;
}
.head-text{
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    align-items: center;

    .user-edit-top{
        position: absolute;
        right: 0px;
        top: -4px;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        background: gray;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: grid;
        place-items: center;

        .user-icon{
            color: white;
        }
        span{
            width: 14px !important;
            height: 14px !important;
        }

    }

    @media screen and (max-width: $bp-mobile){
        .user-edit-top{
            background: white;
            right: 5px;
            top: 0px;

            .user-icon{
                color: darkgrey;
                width: 20px;
            }
            span{
                width: 24px !important;
                height: 24px !important;
            }
        }
    }
}
.head-text{
    @media screen and (max-width: $bp-mobile){
        font-weight: 600;
    }
}


.profile{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: $bp-mobile){
        margin-top: 0;
        flex-direction: row;
        justify-content: space-evenly;
    }
}
.user-info{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;  
}
.username{
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.3rem;
}
.phone{
    font-size: 0.8rem;
    color: $gray3;
    margin-bottom: 0.3rem;
}
.email{
    font-size: .8rem;
    color: $gray3;
    margin-bottom: 1rem;
}
.nav{
    padding: 10px 0;
    @media screen and (max-width: $bp-mobile){
        display: none;
    }   
}
.nav-button{
    padding: 1rem;
    font-size: 0.9rem;
    cursor: pointer;
    white-space: nowrap;
}
.nav-button_active{
    background: #f3f9f8;
}
.body{
    flex: 0.8;
}
.wallet{
    display: flex;
    width: 100%;
    box-shadow: 0 0 2px 0 #cccccc;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}
.credit{
    flex: 1;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right:1px solid #e5e5e5;
    border-bottom:1px solid #e5e5e5;
    background: #fff;
    
    @media screen and (max-width: $bp-mobile){
            flex-direction: column;
            align-items: center;
            padding: 0.6rem 0;
        }
}
.mobile{
    display: none;
    @media screen and (max-width: $bp-mobile){
        display: block;
        margin-right: 4px;
    }
}
.desktop{
    margin-right: 4px;
    @media screen and (max-width: $bp-mobile){
        display: none;
    }
}
.credit:last-child{
    border-right: none;
}
.credit-pre{
    display: flex;
    font-weight: 500;
    margin-right: 4px;
    @media screen and (max-width: $bp-mobile){
        margin-right: 0;
    }
}
.credit-price{
    font-weight: 500;
    background: $yellow0;
    padding: 0.1rem 0.6rem;
    border-radius: 20px;
}

.content{
    background: #fff;
    box-shadow: 0 0 2px 0 #cccccc;
    border-radius: 4px;
    padding: 0.6rem 1.2rem 1.2rem;
    @media screen and (max-width: $bp-mobile){
        display: none;
    }
}

.history,
.coupon,
.payment-card,
.membership,
.storage,
.address
{
    margin-top: 1rem;
}
.addr-tab{
    padding: .8rem 0;
    border-bottom: 1px solid #e5e5e5;
}
.addr-title{
    font-size: 0.8rem;
    color: $gray4;
}
.addr-body{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.addr-desc{
    margin-right: 10px;
    font-size: 1rem;
    font-weight: 700;
    color: $gray2;
}

.edit-address{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 0;
    border-bottom: 1px solid #e5e5e5;
    font-size: 0.9rem;
    font-weight: 600;
}
.add-address{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .8rem 0;
    font-size: 0.9rem;
    font-weight: 600;
}

.payment-card{
    margin-top: 1rem;
}
.payment-text{
    font-size: 1.2rem;
    font-weight: 600;
}

.card{
    padding: 0.8rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
}
.card-detail{
    margin-left: 1rem;
}
.card-info{
    display: flex;
    align-items: center;
}

.add-payment-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    font-size: 0.9rem;
    font-weight: 600;
}
.card-no{
    font-size: 0.7rem;
    color: $gray4;
}

</style>